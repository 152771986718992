<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/lubricationTask' }">润滑计划</el-breadcrumb-item>
          <el-breadcrumb-item>计划详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" ref="ruleForm" label-width="100px">
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="润滑代号：" prop="taskRuleName">
                {{ form.taskCode }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="润滑类型：" prop="patrols">
                {{ form.lubeType==0 ? '补加油' : '换底油' }}
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="起止日期：" prop="name">
                {{form.startDate ? dayjs(form.startDate).format("YYYY-MM-DD") : ""}}
                ~
                {{ form.endDate ? dayjs(form.endDate).format("YYYY-MM-DD") : "" }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="润滑设备：" prop="patrols">
                {{ deviceName ? deviceName : '无' }}
              </el-form-item>
            </el-col>
          </el-row> -->
          
          <div class="form-title">设备信息</div>
          <div class="table-list" style="margin-bottom:20px;">
            <el-table :data="form.deviceInfos" style="width: 100%">
              <el-table-column label="序号" type="index" width="50"></el-table-column>
              <el-table-column prop="alias" label="设备别名"></el-table-column>
              <el-table-column prop="ein" label="设备识别号">
                <template slot-scope="scope">
                  {{ scope.row.ein ? scope.row.ein : '-' }}
                </template>
              </el-table-column>
              <el-table-column prop="deviceName" label="设备名称"></el-table-column>
              <el-table-column prop="specification" label="规格参数"></el-table-column>
              <el-table-column prop="deviceTypeName" label="设备类型"></el-table-column>
              <el-table-column prop="patrolName" label="巡视点名称"></el-table-column>
              <el-table-column prop="facilityName" label="设施位置"></el-table-column>
            </el-table>
            <!-- <page :size="size" :page="page" :total="total" v-on:pageChange="pageChange"></page> -->
          </div>

          <div class="form-title">润滑人员配置</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="润滑人员">
                <div class="staffBox">
                  <div v-if="form.staffInfos">
                    <el-tag v-for="(item, index) in form.staffInfos" :key="index" style="margin-right: 4px;">
                      {{ item.staffName }}
                    </el-tag>
                  </div>
                  <div v-else style="color: #909399;text-align: center;">无润滑人员</div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12"></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24" class="lubri-col">
              <div class="lubri-tabs">润滑周期选择</div>
              <el-tabs v-model="activeName">
                <el-tab-pane label="自定义" name="custom" disabled>
                  <div class="config-box">
                    <el-row :gutter="20" v-if="form.customFrequencyInfo">
                      <el-col :span="12">
                        <el-form-item label="第一次润滑日期：" label-width="140px" prop="firstLubriDate">
                          {{ form.customFrequencyInfo.firstDate ? dayjs(form.customFrequencyInfo.firstDate).format("YYYY-MM-DD") : "" }}
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-row :gutter="20" class="cycles">
                          <el-col :span="9">
                            <el-form-item label="规律循环：">
                                {{ form.customFrequencyInfo.cycleDays }} 天
                            </el-form-item>
                          </el-col>
                          <el-col :span="12">
                            <el-form-item :label="`(每隔${form.customFrequencyInfo.cycleDays?form.customFrequencyInfo.cycleDays:'x'}天会自动生成一次润滑计划)`" label-width="240px"></el-form-item>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" v-if="form.customFrequencyInfo">
                      <div class="setWeek">
                        <div class="box">
                          <div class="week-title">
                            <el-form-item label="润滑工作时间段" label-width="110px"></el-form-item>
                          </div>
                          <div v-for="(cDate, cIndex) in form.customFrequencyInfo.customTimePeriods" :key="cIndex" class="date">
                            <el-row :gutter="20">
                              <el-col :span="1">
                                <span class="date-num">{{cIndex+1}}.</span>
                              </el-col>
                              <el-col :span="6">开始时间：
                                <span style="position: relative;">{{ cDate.startTime ? dateSecond(cDate.startTime) : cDate.startTime==0 ? "00:00:00" : "" }}</span>
                              </el-col>
                              <el-col :span="6">结束时间：
                                <span style="position: relative;">{{ cDate.endTime ? dateSecond(cDate.endTime) : "" }}</span>
                              </el-col>
                            </el-row>
                          </div>
                        </div>
                      </div>
                    </el-row>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="周配置" name="weekConfig" disabled>
                  <div class="config-box">
                    <div class="setWeek">
                      <el-form-item label="工作时间">
                        <div class="box" v-for="(item, index) in form.weekFrequencyInfos" :key="index">
                          <div class="week-title">
                            <span v-if="item.weekIndex == 0">星期日</span>
                            <span v-if="item.weekIndex == 1">星期一</span>
                            <span v-if="item.weekIndex == 2">星期二</span>
                            <span v-if="item.weekIndex == 3">星期三</span>
                            <span v-if="item.weekIndex == 4">星期四</span>
                            <span v-if="item.weekIndex == 5">星期五</span>
                            <span v-if="item.weekIndex == 6">星期六</span>
                            <span v-for="(i, ind) in item.weekTimePeriods" :key="ind">
                              <el-row :gutter="20">
                                <el-col :span="1">
                                  <span class="date-num">{{ind+1}}.</span>
                                </el-col>
                                <el-col :span="6">开始时间：
                                  <span style="position: relative;">{{ i.startTime ? dateSecond(i.startTime) : i.startTime==0 ? "00:00:00" : "" }}</span>
                                </el-col>
                                <el-col :span="6">结束时间：
                                  <span style="position: relative;">{{ i.endTime ? dateSecond(i.endTime) : "" }}</span>
                                </el-col>
                              </el-row>
                            </span>
                          </div>
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/lubricationTask')">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      loading: false,
      form: {
        startDate: 0,
        endDate: 0,
        patrols: [],
      },
      row: {},
      // tabs
      activeName: "custom",
      patrolName: '',
      deviceName: ''
    }
  },
  methods: {
    dayjs,
    dateSecond(val) {
      let s = val % 60;
      let _s = val - s;

      let m = (_s / 60) % 60;

      let h = (_s / 60 - m) / 60;
      return `${h > 9 ? h : "0" + h}:${m > 9 ? m : "0" + m}:${
        s > 9 ? s : "0" + s
      }`;
    },
    loadLubeDetail() {
      this.loading = true
      this.$ajax.post("lubeTaskDetail", {
        id: this.row.id,
      }).then((res) => {
        sessionStorage.setItem("detail", true);
        this.form = res.data
        this.patrolName = this.form.deviceInfos[0].patrolName
        this.deviceName = this.form.deviceInfos[0].deviceName
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    }
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push("/lubricationTask");
      return;
    }
    this.row = this.$route.params.row;
    if(this.row.cycle==0) {
      this.activeName = 'weekConfig'
    }else {
      this.activeName = 'custom'
    }
    this.loadLubeDetail()
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  max-width: 1000px;
}
.config-box {
  background-color: #FAFAFA;
  border-radius: 10px;
  margin: 10px;
  padding: 30px 0 5px;
}
.staffBox {
  background: #fff;
  margin-right: 20px;
  height: 40px;
  border-radius: 4px;
  padding: 0 4px;
  border: 1px solid #e7e9ee;
}
.setWeek {
  border-top: 0;
  .date {
    padding-bottom: 20px;
    padding-left: 80px;
    font-size: 14px;
  }
  .date-num {
    font-size: 12px;
    margin: 0 10px;
  }
  .week-title {
    font-size: 14px;
    padding-bottom: 10px;
    margin-left: 25px;
    span {
      margin-right: 20px;
    }
    i {
      font-weight: bold;
      color: #1494fb;
      margin-left: 20px;
      cursor: pointer;
    }
  }
  .title {
    font-size: 14px;
    padding-left: 10px;
    line-height: 30px;
    background-color: #f6f7fb;
  }
  .box {
    padding: 0 10px;
    margin-bottom: 6px;
  }
  .box:last-child {
    margin: 0;
  }
}
.lubri-col /deep/.el-tabs__nav {
  left: 140px;
}
.lubri-col /deep/.el-tabs__nav-wrap::after {
  position: static !important;
}
.lubri-tabs {
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  margin-left: 30px;
  position: absolute;
}
.cycles /deep/.el-input__inner {
  text-align: center;
}
</style>